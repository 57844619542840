<template>
  <main>
    <Header class="header" />

    <slot />
    <LazySectionRSE />
    <LazySectionNewsletter />
    <LazySectionSkeepers />
    <LazyFooter />
    <!-- <SpeedInsights /> -->
  </main>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
@use '$/z-index.scss';

// main .header {
//   z-index: z-index.$header;
// }
</style>
