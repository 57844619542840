<template>
  <LazyHeaderBand v-if="headerBand.isDisplayed" :header-band="headerBand" />
  <div class="header-container">
    <div v-if="header" class="header">
      <div class="logo-container">
        <Icon v-if="!navOpened" class="burger" name="burger" @click="navOpened = true" />
        <Icon v-else class="burger" name="close" @click="navOpened = false" />
        <NuxtLink to="/">
          <img src="/assets/logo/mv-logo--blue.svg" alt="logo" class="logo"
        /></NuxtLink>
      </div>

      <LazyHeaderNav
        v-if="show"
        v-model:opened="navOpened"
        :menus="header.navButtons ?? []"
        class="nav-bar"
      />

      <div class="right">
        <NuxtLink
          v-for="menu of header.rightButtons?.filter(button => button.icon) ?? []"
          :key="menu.id"
          :to="menu.icon"
        >
          <Icon
            v-if="menu.icon"
            class="icon"
            :class="{ desktop: false }"
            :name="menu.icon"
            fill="black"
          />
        </NuxtLink>
        <div v-if="!isHomePage" class="search">
          <LazyGlobalSearchInput
            v-if="!breakpoints.isMobile"
            small
            :placeholder-text="defaultPlaceholdersearch"
          ></LazyGlobalSearchInput>
          <div v-else>
            <Icon
              class="icon-search-modal"
              name="search"
              size="23px"
              @click="isDisplayModal = !isDisplayModal"
            />
            <LazyModal v-if="isDisplayModal" v-model="isDisplayModal">
              <LazyGlobalSearchInput
                :placeholder-text="defaultPlaceholdersearch"
              ></LazyGlobalSearchInput
            ></LazyModal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const breakpoints = useBreakpoints();
const headerStore = useHeaderStore();
const { header, headerBand } = storeToRefs(headerStore);

const show = ref(false);
onMounted(() => {
  show.value = true;
});

const navOpened = $ref(false);
const isDisplayModal = $ref(false);

const route = useRoute();

const isHomePage = computed(() => route.path === '/');

const defaultPlaceholdersearch = 'Rechercher un voyage';

const footerStore = useFooterStore();
const { offices } = storeToRefs(footerStore);

// josn+ld for organization
useJsonld({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'MARCO VASCO',
  description:
    'MARCO VASCO est une soci\u00E9t\u00E9 sp\u00E9cialis\u00E9e dans le voyage sur mesure. Plus de 150 sp\u00E9cialistes de leur destination vous aident a personnaliser votre itin\u00E9raire pour vivre une exp\u00E9rience unique.',
  url: 'https://www.marcovasco.fr/',
  logo: 'https://www.marcovasco.fr/sites/all/themes/mv_new/images/MV_LOGO_BLANC.svg',
  alternateName: 'MARCOVASCO.FR',
  telephone: `${offices?.value[0]?.phoneNumber}`,
  address: {
    '@type': 'PostalAddress',
    streetAddress: `${offices?.value[0]?.address}`,
    addressLocality: 'Paris',
    postalCode: '75015',
    addressCountry: 'France'
  },
  founders: [
    {
      '@type': 'Person',
      name: 'Geoffroy De Becdelievre'
    },
    {
      '@type': 'Person',
      name: 'Mathieu Bouchara'
    }
  ],
  foundingDate: '2007-12-12',
  contactPoint: [
    {
      '@type': 'ContactPoint',
      contactType: 'customer service',
      telephone: '+33176647490',
      areaServed: 'FR'
    }
  ],
  sameAs: [
    'https://www.facebook.com/MARCOVASCO',
    'https://x.com/marcovasco',
    'https://www.youtube.com/c/marcovasco',
    'https://www.tiktok.com/@marcovascofr',
    'https://www.instagram.com/marcovascofr/',
    'https://www.societe.com/societe/marco-vasco-501602007.html',
    'https://www.linkedin.com/company/marcovasco/'
  ]
});
</script>

<style lang="scss" scoped>
@use '$/animation.scss';
@use '$/breakpoints.scss';
@use '$/metrics.scss';
@use '$/mouse.scss';
@use '$/shadows.scss';
@use '$/z-index.scss';

.header-container {
  position: sticky;
  z-index: z-index.$header;
  top: 0;

  flex-direction: column;

  background-color: white;
  box-shadow: shadows.$menu;

  .header {
    align-items: center;
    justify-content: center;
    height: metrics.$header-height;
    padding: 0 40px;

    @include breakpoints.mobile() {
      justify-content: space-between;
      height: metrics.$header-height-mobile;
      padding: 10px 40px;
    }

    .right,
    .logo-container {
      position: absolute;
      top: 0;

      @include breakpoints.mobile() {
        position: static;
      }
    }

    .logo-container {
      left: 20px;
      gap: 16px;
      align-items: center;

      .logo {
        width: 300px;
        height: 60px;

        @include breakpoints.mobile() {
          width: 250px;
          height: 40px;
        }
      }

      .burger {
        display: none;

        @include breakpoints.mobile() {
          @include animation.vue-transition-fade(0.15s);

          display: flex;
          width: 28px;
        }
      }
    }

    .right {
      right: 40px;
      gap: 32px;
      align-items: center;
      height: 100%;

      @include breakpoints.mobile() {
        gap: 16px;
      }

      .search {
        display: flex;
        justify-content: end;
      }

      .icon {
        @include mouse.clickable-opacity(0.65, -0.2);

        width: 28px;
        height: 28px;

        @include breakpoints.mobile() {
          width: 18px;
          height: 18px;

          :deep(svg) {
            height: 18px;
          }

          &.desktop {
            display: none;
          }
        }
      }
    }
  }
}
</style>
